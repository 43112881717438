/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Stack,
  Button,
  Box,
  Container,
  Typography,
  TextField,
  Modal,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { http } from "../services/http.service";
import { useNavigate } from "react-router-dom";
import Page from '../components/Page';
import Iconify from '../components/Iconify';

const UserDetail = () => {

  const navigate = useNavigate();

  const { id } = useParams();
  const { instance, accounts } = useMsal();

  const [user, setUser] = useState(null);
  const [organizations, setOrganizations] = useState([])
  const [principalName, setPrincipalName] = useState("")
  const [orgId, setOrgId] = useState("");

  useEffect(() => {
    reloadUser();
    reloadOrganizations();
  }, [])

  // **************
  // Loading user
  const reloadUser = () => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.get(`User/GetByUserId/${id}`, response.accessToken).then((tuple) => {

        var u = {
          id: tuple.item1.id,
          firstname: tuple.item1.firstname,
          surname: tuple.item1.surname,
          email: tuple.item1.email,
          type: tuple.item1.type,
          status: tuple.item1.status,
          organizationId: tuple.item1.organizationId,
          indicatedOrganization: tuple.item2["extension_ea30de991e604202bfdabc6dc7458432_Organization"] === undefined ? "N/A" : tuple.item2["extension_ea30de991e604202bfdabc6dc7458432_Organization"],
          phoneNumber: tuple.item2["extension_ea30de991e604202bfdabc6dc7458432_PhoneNumber"] === undefined ? "N/A" : tuple.item2["extension_ea30de991e604202bfdabc6dc7458432_PhoneNumber"]

        }
        setUser(u);
        //setUser(user);
        //setPrincipalName(user["firstname"] + "." + user["surname"] + "@standbyb2c.onmicrosoft.com");
        //console.log(user);
      }).catch((e) => {
        console.log(e)
      })
    })
  }
  // ****************


  // **************
  // Loading organisation
  const reloadOrganizations = () => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.get("User/AllOrganizations", response.accessToken).then((orgs) => {
        setOrganizations(orgs);
      }).catch((e) => {
        console.log(e);
      })
    })
  }
  // *****************


  const sortedOrganizations = () => {
    // order an array of objects with name
    return organizations.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }


  // ****************
  // Form field input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  // ***************


  // **************
  // Input validation

  const isEmailOk = (input) => {
    if (input === undefined) return false;

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return input.match(validRegex) != null
  }

  const hasValue = (input) => {
    return input == null ? false : (input).toString().length > 0;
  }

  const nullToStr = (input) => {
    return input == null ? "" : input.toString();
  }

  const formHasErrors = () => {
    return !(hasValue(user["organizationId"]) &&
      hasValue(user["firstname"]) &&
      hasValue(user["surname"]) &&
      hasValue(user["phoneNumber"]) &&
      hasValue(user["type"]) &&
      isEmailOk(user["email"]))
  }
  // **************


  // ***************    
  // UseStates and functions: DELETE USER
  //
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState({});

  const deleteUser = () => {
    setConfirmDeleteUser(user);
    setOpenConfirmDelete(true);
  }

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleConfirmDeleteUser = () => {
    setOpenConfirmDelete(false);

    // Delete from Azure if status is INACTIVE
    if (user["status"] === 3) {
      const options = {
        account: accounts[0],
        interactionType: InteractionType.Silent,
        scopes: ["https://graph.microsoft.com/User.ReadWrite.All"],
      };

      instance.acquireTokenSilent(options).then((response) => {
        const requestOptions = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${response.accessToken}`,
          },
          body: ""
        };

        const url = `https://graph.microsoft.com/v1.0/users/` + principalName

        fetch(url, requestOptions).then((resp) => {
          deleteUserFromDb(confirmDeleteUser.id);
        });
      })
    }
    else {
      deleteUserFromDb(confirmDeleteUser.id);
    }
  };

  const deleteUserFromDb = (userId) => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/133e1b85-6f0c-4c29-ac0a-f6c7c48c082c/DB.User.ReadWrite.All"]
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.delete("User/Delete/" + userId, response.accessToken).then((users) => {
        navigate("/user/");
      }).catch((e) => {
        console.log(e)
      })
    })
  }

  //
  // ******************

  // ***********************
  // UseStates and functions: Organisations
  //
  const handleOpenOrganisations = () => {
    setErrorText_AddOrg("");
    setErrorText_DeleteOrg("");
    setOpenOrganisations(true);
  }
  const handleCloseOrganisations = () => setOpenOrganisations(false);

  const [orgName, setOrgName] = useState("");
  const [orgNumber, setOrgNumber] = useState("");
  const [openOrganisations, setOpenOrganisations] = useState(false);
  const [errorText_AddOrg, setErrorText_AddOrg] = useState("");
  const [errorText_DeleteOrg, setErrorText_DeleteOrg] = useState("");

  const removeOrganization = () => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/133e1b85-6f0c-4c29-ac0a-f6c7c48c082c/DB.User.ReadWrite.All"],
    };

    instance.acquireTokenSilent(options).then((response) => {

      if (!orgId) return;

      http.delete(`Organization/Remove/${orgId}`, response.accessToken).then(() => {
        setOrgId("")
        reloadOrganizations();
      }).catch((e) => {
        setErrorText_DeleteOrg("Could not delete organisation. You can't remove organisations having connected users.")
        console.log(e)
      })
    });
  }

  const addOrganization = () => {

    if (orgName === "") return;
    if (orgNumber === "") return;

    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/133e1b85-6f0c-4c29-ac0a-f6c7c48c082c/DB.User.ReadWrite.All"],
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.post(`Organization/Add`, { OrganizationNumber: orgNumber, Name: orgName }, response.accessToken).then(() => {
        reloadOrganizations();
        setOrgNumber("");
        setOrgName("");
      }).catch((e) => {
        setErrorText_AddOrg("Could not add organisation. An organisation name must be unique.")
        console.log(e)
      })
    });
  }

  // ***************    
  // UseStates and functions: SAVE USER
  //
  const [openSaveResult, setOpenSaveResult] = useState(false);
  const [saveResult, setSaveResult] = useState(false);
  const [saveResultHeader, setSaveResultHeader] = useState("");
  const [saveResultDescription, setSaveResultDescription] = useState("");

  const inactivateUser = () => {

    // 1. Inactivate user
    instance.acquireTokenSilent({
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/133e1b85-6f0c-4c29-ac0a-f6c7c48c082c/DB.User.ReadWrite.All"],
    }).then((responseTokenGraph) => {
      user.status = 3;
      http.post("User/SetUserProps", user, responseTokenGraph.accessToken).then((resp) => {
        setSaveResultHeader("User inactivated")
        setSaveResultDescription("User account is inactivated and can no longer log in.")
        setSaveResult(true);
        setOpenSaveResult(true);
      })
    }).catch((e) => {
      setSaveResultHeader("Save failed")
      setSaveResultDescription("User could not be inactivated! (" + e.description + ")")
      setOpenSaveResult(true);
      setSaveResult(false);
    })

  }

  const saveAndActivateUser = () => {

    // 1. Input validation
    if (formHasErrors()) {
      setSaveResultHeader("Save failed")
      setSaveResultDescription("Review the input fields and correct errors")
      setOpenSaveResult(true);
      setSaveResult(false);
      return;
    }

    // 2. Activate user
    instance.acquireTokenSilent({
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/133e1b85-6f0c-4c29-ac0a-f6c7c48c082c/DB.User.ReadWrite.All"],
    }).then((responseTokenGraph) => {
      user.status = 2;
      http.post("User/SetUserProps", user, responseTokenGraph.accessToken).then((resp) => {
        setSaveResultHeader("Save successful")
        setSaveResultDescription("User successfully saved and activated")
        setSaveResult(true);
        setOpenSaveResult(true);
      })
    }).catch((e) => {
      setSaveResultHeader("Save failed")
      setSaveResultDescription("User could not be saved or activated (" + e.description + ")")
      setOpenSaveResult(true);
      setSaveResult(false);
    })
  }

  const handleSetOpenSaveResult = () => {
    setOpenSaveResult(false);
    if (saveResult) {
      navigate("/user/");
    }
  }

  return (
    <Page title="Modify user">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>

          <Typography variant="h4" gutterBottom sx={{ color: "white" }}>
            <Button variant="outline" onClick={() => window.history.go(-1)}>Go back</Button>
          </Typography>
        </Stack>
        {
          user !== null &&
          <Card sx={{ minWidth: 1000, minHeight: 600, p: 4 }}>
            <Stack justifyContent="flex-start" direction="row" spacing={8}>
              <Stack spacing={4} justifyContent="flex-start">
                <Typography variant="h6" gutterBottom>
                  User details
                </Typography>
                <TextField onChange={handleInputChange}
                  required
                  name="firstname"
                  label="First name"
                  defaultValue={user.firstname}
                  error={!hasValue(user["firstname"])}
                />
                <TextField onChange={handleInputChange}
                  required
                  name="surname"
                  label="Surname"
                  defaultValue={user.surname}
                  error={!hasValue(user["surname"])}
                />
                <TextField onChange={handleInputChange}
                  name="phoneNumber"
                  label="Phone"
                  defaultValue={user.phoneNumber}
                  error={!hasValue(user["phoneNumber"])}
                />
                <TextField onChange={handleInputChange}
                  disabled={true}
                  name="email"
                  label="Email"
                  defaultValue={user.email}
                  sx={{ width: 600 }}
                  error={!isEmailOk(user["email"])}
                />
                <FormControl variant="outlined" error={!hasValue(user["type"])}>
                  <FormLabel>User Type</FormLabel>
                  <RadioGroup onChange={handleInputChange}
                    name="type"
                    defaultValue=""
                    value={nullToStr(user["type"])}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Standby user" />
                    <FormControlLabel value="2" control={<Radio />} label="Upfitter" />
                    <FormControlLabel value="3" control={<Radio />} label="End user" />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Stack spacing={4} justifyContent="flex-start">
                <Typography variant="h6" gutterBottom>
                  Organisation details
                </Typography>
                <Stack justifyContent="center" direction="row">
                  <TextField fullWidth onChange={handleInputChange}
                    required
                    name="indicatedOrganization"
                    label="Indicated organisation"
                    sx={{ width: 400 }}
                    defaultValue={user.indicatedOrganization}
                    disabled={true}
                  />
                </Stack>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Organisation</InputLabel>
                  <Select onChange={handleInputChange}
                    required
                    labelId="demo-simple-select-label"
                    name="organizationId"
                    label="Organization"
                    defaultValue=""
                    error={!hasValue(user["organizationId"])}
                    value={nullToStr(user["organizationId"])}
                  >
                    { /* <MenuItem key="" value="">Unknown</MenuItem> */}
                    {
                      sortedOrganizations().map((org) => (<MenuItem key={org.id} value={org.id}>{org.name} {org.organizationNumber === null ? "" : "(" + org.organizationNumber + ")"}</MenuItem>))
                    }
                  </Select>
                  <Button sx={{ marginTop: 1 }} onClick={handleOpenOrganisations}>Manage Organizations</Button>
                </FormControl>
              </Stack>
            </Stack>
            <Box sx={{ my: 4, p: 4 }}>
              {(user["status"] === 1) &&
                /* Save to DB and Create/Update account in azure */
                <Button onClick={saveAndActivateUser} variant="contained" sx={{ marginRight: 1 }} color="primary" startIcon={<Iconify icon="ant-design:check-circle-twotone" />}>
                  Save and activate user
                </Button>
              }
              {(user["status"] === 2) &&
                /* Save to DB and Create/Update account in azure */
                <Button onClick={saveAndActivateUser} variant="contained" sx={{ marginRight: 1 }} color="primary" startIcon={<Iconify icon="ant-design:check-circle-twotone" />}>
                  Save user
                </Button>
              }
              {(user["status"] === 3) &&
                /* Save to DB and Reenable in azure as https://stackoverflow.com/questions/41438100/how-to-deactivate-and-reactivate-user-in-azure-ad-b2c */
                <Button onClick={saveAndActivateUser} variant="contained" sx={{ marginRight: 1, }} color="primary" startIcon={<Iconify icon="ant-design:check-circle-twotone" />}>
                  Save and reactivate user
                </Button>
              }
              {(user["status"] === 1) &&
                /* Delete user from DB */
                <Button onClick={deleteUser} variant="contained" color="warning" startIcon={<Iconify icon="eva:trash-2-fill" />}>
                  Delete user
                </Button>
              }
              {(user["status"] === 2) &&
                /* Save to DB and Disable in azure as https://stackoverflow.com/questions/41438100/how-to-deactivate-and-reactivate-user-in-azure-ad-b2c */
                <Button onClick={inactivateUser} variant="contained" color="warning" startIcon={<Iconify icon="ant-design:minus-circle-outlined" />}>
                  Inactivate user
                </Button>
              }
              {(user["status"] === 3) &&
                /* Delete from DB and Delete from azure */
                <Button onClick={deleteUser} variant="contained" color="warning" startIcon={<Iconify icon="eva:trash-2-fill" color="white" />}>
                  Delete user
                </Button>
              }
            </Box>
          </Card>
        }
        {
          user === null &&
          <Card sx={{ minWidth: 1000, minHeight: 600, p: 4 }}>
            <LinearProgress />
          </Card>
        }
        <Dialog
          open={openSaveResult}
        >
          <DialogTitle>
            {saveResultHeader}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {saveResultDescription}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSetOpenSaveResult}>OK</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmDelete}
          onClose={handleCloseConfirmDelete}
        >
          <DialogTitle id="alert-dialog-title">
            Delete user
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete user {confirmDeleteUser.firstname}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeleteUser}>Confirm</Button>
            <Button onClick={handleCloseConfirmDelete} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={openOrganisations}
          onClose={handleCloseOrganisations}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}>
            <Typography variant="h6" gutterBottom>
              Manage organizations
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Organisation</InputLabel>
                <Select onChange={(e) => { setOrgId(e.target.value); setErrorText_AddOrg(""); setErrorText_DeleteOrg("") }}
                  required
                  id="orgList"
                  labelId="demo-simple-select-label"
                  name="organizationId"
                  label="Organization"
                  defaultValue=""
                >
                  {/* <MenuItem key="" value="">Unknown</MenuItem> */}
                  {
                    organizations.map((org) => (<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>))
                  }
                </Select>
              </FormControl>
              <Button disabled={orgId == ""} onClick={() => removeOrganization(user["organizationId"])} variant="contained" color="primary" sx={{ ml: 2 }}>Remove</Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }} visibility={errorText_DeleteOrg !== ""}>
              <Typography variant="string" color="error" gutterBottom>
                {errorText_DeleteOrg}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextField sx={{ mr: 1 }} onChange={(e) => { setOrgNumber(e.target.value); setErrorText_AddOrg(""); setErrorText_DeleteOrg("") }}
                required
                id="orgNumber"
                name="New organization number"
                label="Organization number"
                value={orgNumber}
              />
              <TextField onChange={(e) => { setOrgName(e.target.value); setErrorText_AddOrg(""); setErrorText_DeleteOrg("") }}
                required
                id="orgName"
                name="New organization name"
                label="Organization name"
                value={orgName}
                fullWidth
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }} visibility={errorText_AddOrg !== ""}>
              <Typography variant="string" color="error" gutterBottom>
                {errorText_AddOrg}
              </Typography>
            </Box>
            <Button disabled={orgName === "" || orgNumber === ""} onClick={() => addOrganization()} variant="contained" color="primary" sx={{ mt: 1 }}>Add new Organization</Button>
          </Box>
        </Modal>
      </Container>
    </Page >
  );
}

export default UserDetail;
