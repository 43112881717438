/* eslint-disable */
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from '@mui/material';
import { http } from "../services/http.service";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
// components
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import PropagateLoader from "react-spinners/PropagateLoader";
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock


// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'firstname', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function applyFilter(array, query, status) {
  let tmpArray = [];
  if (!query) {
    tmpArray = array;
  }
  else {
    tmpArray = filter(array, (_user) => (_user.organization != null && _user.organization.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
      _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      _user.surname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return filter(tmpArray, (_user) => (_user.status === status || status === 0))
}

export default function User() {

  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('status');

  const [filterName, setFilterName] = useState('');

  const [loading, setLoading] = useState(true);

  // 0=All, 1=New, 2=Active, 3=Inactive
  const [userStatusFilter, setUserStatusFilter] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    ReloadUsers();
  }, [])

  const ReloadUsers = () => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
    };

    setLoading(true);

    instance.acquireTokenSilent(options).then((response) => {
      http.get("User/All", response.accessToken).then((users) => {
        setUserList(users);
        setLoading(false);
      }).catch((e) => {
        console.log(e);
      })
    })
  }

  const DeclineUser = (userId) => {

    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.post("User/Decline/" + userId.id, response.accessToken).then((users) => {
        setUserList(userList.filter((user) => {
          return user.id != userId
        }));

        setSelected(selected.filter((selectedUserId) => {
          return selectedUserId != userId.id
        }));

      }).catch((e) => {
        console.log(e)
      })
    })

  }

  const DeleteUser = (userId) => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.delete("User/Delete/" + userId, response.accessToken).then((users) => {
        setUserList(userList.filter((user) => {
          return user.id != userId
        }));

        setSelected(selected.filter((selectedUserId) => {
          return selectedUserId != userId
        }));

      }).catch((e) => {
        console.log(e)
      })
    })
  }

  const DeleteUsers = (userIdArray) => {
    const options = {
      account: accounts[0],
      interactionType: InteractionType.Silent,
      scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
    };

    instance.acquireTokenSilent(options).then((response) => {
      http.post("User/DeleteMany/", { "UserIds": userIdArray }, response.accessToken).then(() => {
        const newUserlist = userList.filter((user) => {
          return !userIdArray.includes(user.id)
        });
        setSelected([]);
        setUserList(newUserlist);
      }).catch((e) => {
        console.log(e)
      })
    })
  }

  const ShowConfirmDeleteUser = (user) => {
    setConfirmDeleteUser(user);
    setOpenConfirmDelete(true);
  }

  const ShowDeclineUser = (user) => {
    setConfirmDeclineUser(user);
    setOpenConfirmDecline(true);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = (orderBy === property && order === 'asc');
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteSelectedClicked = () => {
    setOpenConfirmDeleteSelected(true);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterOnUserStatus = (event) => {
    setUserStatusFilter(parseInt(event.target.value));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const filteredUsers = applySort(applyFilter(userList, filterName, userStatusFilter), getComparator(order, orderBy));
  const isUserNotFound = filteredUsers.length === 0;

  const getStatusText = (status) => {
    if (status === 1) {
      return "New";
    } else if (status === 2) {
      return "Active";
    } else if (status === 3) {
      return "Inactive";
    } else if (status === 4) {
      return "Declined";
    } else {
      return "Unknown";
    }
  }

  const getStatusColor = (status) => {
    if (status === 1) {
      return "info";
    } else if (status === 2) {
      return "success";
    } else if (status === 3) {
      return "error";
    } else if (status === 4) {
      return "warning";
    } else {
      return "default";
    }
  }
  const [openConfirmDecline, setOpenConfirmDecline] = useState(false);
  const [confirmDeclineUser, setConfirmDeclineUser] = useState({});

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState({});

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleConfirmDeleteUser = () => {
    setOpenConfirmDelete(false);
    DeleteUser(confirmDeleteUser.id);
    ReloadUsers();
  };

  const handleDeclineUser = () => {
    setOpenConfirmDecline(false);
    DeclineUser(confirmDeclineUser);
    ReloadUsers();
  };

  const [openConfirmDeleteSelected, setOpenConfirmDeleteSelected] = useState(false);

  const handleCloseConfirmDeleteSelected = () => {
    setOpenConfirmDeleteSelected(false);
  };

  const handleConfirmDeleteSelected = () => {
    setOpenConfirmDeleteSelected(false);

    DeleteUsers(selected);

    /*
    selected.map((userId) => {
      DeleteUser(userId);
    });
    */

  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        {/*}
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
          */}
      </Stack>
      <Card sx={{ width: 1000, mb: 10, pb: 20, pt: 4, minHeight: "75%" }}>
        <UserListToolbar numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          userStatus={userStatusFilter}
          onFilterOnUserStatus={handleFilterOnUserStatus}
          onDeleteSelectedClicked={handleDeleteSelectedClicked}
        />
        <Scrollbar>
          {loading ?
            <Box sx={{ display: "flex", flexDirection: "column", height: 800, justifyContent: "center", alignItems: "center" }}>
              <PropagateLoader size={32} color="#0077c8" />
              <strong style={{ marginTop: 35 }}>Hämtar användare...</strong>
            </Box>
            :
            <TableContainer sx={{ width: "100%" }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, organization, firstname, surname, status } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{organization}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {firstname} {surname}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={getStatusColor(status)}>
                            {getStatusText(status)}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <UserMoreMenu
                            onDeclineClicked={() => ShowDeclineUser(row)}
                            onDeleteClicked={() => ShowConfirmDeleteUser(row)}
                            user={row}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          }
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <div>
        <Dialog
          open={openConfirmDecline}
          onClose={() => setOpenConfirmDecline(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Decline User
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Decline user {confirmDeleteUser.firstname}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeclineUser}>Confirm</Button>
            <Button onClick={() => setOpenConfirmDecline(false)} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openConfirmDelete}
          onClose={handleCloseConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete user
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete user {confirmDeleteUser.firstname}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeleteUser}>Confirm</Button>
            <Button onClick={handleCloseConfirmDelete} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openConfirmDeleteSelected}
          onClose={handleCloseConfirmDeleteSelected}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete selected users
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete selected users
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDeleteSelected}>Confirm</Button>
            <Button onClick={handleCloseConfirmDeleteSelected} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
