import { Navigate, useRoutes } from 'react-router-dom';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import User from './pages/User';
import UserDetail from "./pages/UserDetail";
import NotFound from './pages/Page404';
import Landing from './pages/Landing';
import SignUp from './pages/SignUp';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <SignUp/> },
        { path: 'landing', element: <Landing /> },
        { path: 'user', element: <User /> },
        { path: "user/:id", element: <UserDetail /> },
        { path: 'signup', element: <SignUp /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
