import { API } from "../config/api"

export const http = {
    get,
    post,
    put,
    delete: _delete
};

function get(url, token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    };
    return fetch(`${API.base_url}/${url}`, requestOptions).then(handleResponse);
}

function post(url, body, token = "") {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(body)
    };
    return fetch(`${API.base_url}/${url}`, requestOptions).then(handleResponse);
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    return fetch(`${API.base_url}/${url}`, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, token) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    }
    return fetch(`${API.base_url}/${url}`, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
    return response.text().then((text) => {

        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}