import { useMsal } from "@azure/msal-react";
import Page from '../components/Page';
import {
    Stack,
    Button,
    Card,
    CardContent,
    CardActions,
    Divider,
    Container,
    Typography,
} from '@mui/material';
import { useState } from "react";
import { useEffect } from "react";
import { InteractionType } from "@azure/msal-browser";
import { http } from "../services/http.service";
import logo from "../logo.png";

export default function Landing() {
    const { instance, accounts } = useMsal();

    const [standbyUser, setStandbyUser] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        reloadUser();
    }, [])

    // **************
    // Loading user
    const reloadUser = () => {
        const options = {
            account: accounts[0],
            interactionType: InteractionType.Silent,
            scopes: ["https://standbyb2c.onmicrosoft.com/d731717e-c40d-4345-bc1b-263d40e3722e/DMP.ALL"],
        };

        setLoading(true);

        instance.acquireTokenSilent(options).then((response) => {
            http.get(`User/GetByUserId/${accounts[0].localAccountId}`, response.accessToken).then((user) => {
                console.log(user)
                setUser(user);
                setStandbyUser(parseInt(user.item1.type) === 1);
                setLoading(false);
            }).catch((e) => {
                console.log(e)
                setLoading(false);
            })
        });
    }
    // ****************

    var dmpUrl = process.env.NODE_ENV === 'production' ? "https://orange-forest-0313c5a03.2.azurestaticapps.net" : "https://localhost:5001/search"

    return (
        <Page title="Standby Customer Portal">
            <Container maxWidth="xl">
                <Typography variant="h3" gutterBottom sx={{ display: "flex", justifyContent: "center", mb: 8, color: "white" }} >
                    <img src={logo} style={{ marginBottom: 20 }} />
                </Typography>
                <Stack direction="row" alignItems="center" justifyContent="start" mb={5} spacing={5}>
                    <Card sx={{ maxWidth: 350 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                Device Management (DMP)
                            </Typography>
                            <Divider />
                            <br />
                            <Typography variant="body1" gutterBottom>
                                Find information about vehicles and installed equipment
                            </Typography>
                            <CardActions>
                                <Button size="small" href={dmpUrl}>Take me there</Button>
                            </CardActions>
                        </CardContent>
                    </Card>
                    {loading ?
                        <Card sx={{ width: 350, height: 200 }}>
                            <CardContent>
                                <strong>Loading..</strong>
                            </CardContent>
                        </Card>
                        :
                        <>
                            <Card sx={{ maxWidth: 350 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        User Management
                                    </Typography>
                                    <Divider />
                                    <br />
                                    <Typography variant="body1" gutterBottom>
                                        Administer portal user accounts and organisations
                                    </Typography>
                                    <CardActions>
                                        <Button size="small" href="/user">Take me there</Button>
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </>
                    }
                </Stack>
            </Container>
        </Page>
    );
}
