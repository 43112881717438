export const msalConfig = {
    auth: {
        clientId: "d731717e-c40d-4345-bc1b-263d40e3722e",
        authority: "https://standbyb2c.b2clogin.com/standbyb2c.onmicrosoft.com/B2C_1_SIGN_UP_SIGN_IN", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.NODE_ENV === 'production' ? "https://tools.standbygroup.com/landing/" : "https://localhost:5001/landing/",
        knownAuthorities: ["https://standbyb2c.b2clogin.com"],
        navigateToRequestUrl: false,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["https://graph.microsoft.com/.default", "openid", "offline_access"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
};