/* eslint-disable */
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { msalConfig } from "./authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import backdrop from "./Standby-Backdrop-02_1920x1080.jpg";

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

import SignUp from "./pages/SignUp";
// ----------------------------------------------------------------------

export default function App() {

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <>
      <img src={backdrop} style={{ position: "fixed", width: "100%", zIndex: -1, backgroundColor: "#0077c8" }} />
      <MsalProvider instance={msalInstance}>
        <ThemeProvider>
          <AuthenticatedTemplate>
            <ScrollToTop />
            <BaseOptionChartStyle />
            <Router />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignUp />
          </UnauthenticatedTemplate>
        </ThemeProvider>
      </MsalProvider >
    </>
  );
}
