/* eslint-disable */
import React, { useState, useEffect } from 'react';
// @ts-ignore
import { PublicClientApplication, } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";
import './SignUp.css'

export default function signUp() {

	const instance = new PublicClientApplication(msalConfig);

	function handleLogin() {
		instance.loginRedirect(loginRequest)
			.then((loginResponse) => {
				console.log("a", loginResponse);
			}).catch((err) => {
				// login failure
				console.log("b", err);
			});
	}

	useEffect(() => {
		handleLogin();
	}, [])
	
};
