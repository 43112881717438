import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Box } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ onDeleteClicked, onDeclineClicked, user }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {
          (user.status === 1 || user.status === 4) &&
          <MenuItem component={RouterLink} to={`/user/${user.id}`} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="ant-design:check-circle-twotone" color="green" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Activate" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
        {user.status !== 4 &&
          < MenuItem onClick={() => {
            setIsOpen(false);
            onDeclineClicked(user);
          }} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="eva:close-circle-outline" color="orange" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Decline" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
        <MenuItem onClick={() => {
          setIsOpen(false);
          onDeleteClicked(user);
        }} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" color="red" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {
          (user.status === 2 || user.status === 3) &&
          <MenuItem component={RouterLink} to={`/user/${user.id}`} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="ant-design:check-circle-twotone" color="green" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Modify" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
      </Menu>
    </>
  );
}
